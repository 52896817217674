import React from "react";
import styled from "styled-components";
import { Input as AntdInput } from "antd";

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled(AntdInput)`
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #1890ff;
  }
`;

export const TogglePasswordVisibilityButton = styled.button`
  position: absolute;
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  color: #888;

  &:hover {
    color: #1890ff;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 0.8rem;
  color: #f5222d;
  margin-top: 0.2rem;
`;
