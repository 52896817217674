import { Button } from "antd";
import styled from "styled-components";

export const CategoryPillContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  gap: 0.5rem;
  margin-top: 0.5rem;
  /* margin-bottom: 0.5rem; */

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* border-radius: 10px; */
  }
`;

export const CategoryPill = styled(Button)<{ selected: boolean }>`
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0.4rem;
  white-space: nowrap;
  transition: 0.2s ease-in-out;

  background-color: ${({ theme, selected }) =>
    selected ? theme["@primary-color"] : "#C2BEBE"};
  color: white;
  box-shadow: none;
  outline: none;

  &:active,
  &:focus,
  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme["@primary-color"] : "#C2BEBE"};
    color: white !important;
    box-shadow: none !important;
    outline: none !important;
  }
`;
