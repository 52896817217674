import styled from "styled-components";
import { Button, Table } from "antd";

export const StyledTable = styled.div`
  .antd-table {
    background-color: white;
    margin-top: 50px;

    .ant-table-thead > tr > th {
      display: none;
    }

    .ant-table-container {
      background-color: white;
    }

    .ant-table-cell {
      padding: 0.5rem;
    }

    p {
      margin: 0;
    }

    .name {
      font-size: 0.875rem;
      line-height: 19px;
      color: black;
      letter-spacing: 0.04em;
    }

    .desc {
      font-size: 0.8rem;
    }
  }
`;

export const ResponsiveRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const RowDivider = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;

  .price {
    color: ${({ theme }) => theme["@primary-color"]};
    font-size: 1.125rem;
    font-weight: 500;
  }
`;

export const ProductInfo = styled.div`
  text-align: left;
  position: relative;
`;
