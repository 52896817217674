import React from "react";
import { Category } from "../../services/Category";
import { CategoryPill, CategoryPillContainer } from "./CategoriesList.styled";
import { CategoriesListProps } from "./CategoriesList.interface";

export const CategoriesList: React.FC<CategoriesListProps> = ({
  categories,
  onCategoryClick,
  selectedCategoryId,
}) => {
  return (
    <CategoryPillContainer>
      <CategoryPill
        onClick={() => onCategoryClick("0")}
        selected={selectedCategoryId === "0"}
      >
        All Categories
      </CategoryPill>
      {categories?.map((category: Category) => (
        <CategoryPill
          key={category._id}
          onClick={() => onCategoryClick(category._id)}
          selected={category._id === selectedCategoryId}
        >
          {category.name}
        </CategoryPill>
      ))}
    </CategoryPillContainer>
  );
};
