import React, { useState } from "react";
import {
  Button,
  HeaderWrapper,
  ActionButtons,
  MobileMenu,
  DesktopMenu,
  ShoppingDot,
  PlainHeader,
} from "./Header.styled";
import { HeaderProps } from "./Header.interface";
import {
  DotChartOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  ShoppingFilled,
} from "@ant-design/icons";
import { Container } from "./../Container/Container";
import logoMain from "./../../assets/imgs/logoMain.png";
import { Dropdown, Image, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";
import { useTheme } from "styled-components";
import { useAppSelector } from "../../store/hooks";
import userLogo from "./../../assets/imgs/user.png";
import { reset } from "../../store/slice";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { Cart } from "../../pages/Cart/Cart";
import { BackSvg } from "../AppSvgs";

const Header: React.FC<HeaderProps> = ({ heading }) => {
  const user = useAppSelector((state) => state.auth.user);
  const cart = useAppSelector((state) => state.global.cart);
  const dispatch = useDispatch();

  const handleLogout = () => {
    toast.success("User logout");
    dispatch(reset());
  };

  const items = [
    {
      key: "0",
      label: <Link to={paths.PROFILE}>View Profile</Link>,
    },
    {
      key: "1",
      label: <Link to={paths.ORDER_VIEW}>View orders</Link>,
    },
    {
      key: "2",
      label: <span onClick={handleLogout}>Logout</span>,
    },
  ];

  if (heading) {
    return (
      <PlainHeader>
        {/* <Container> */}
        <Link to={paths.HOME}>
          <BackSvg />
        </Link>
        <h3>{heading}</h3>
        <div></div>
        {/* </Container> */}
      </PlainHeader>
    );
  }

  return (
    <>
      <Container>
        <HeaderWrapper>
          <Link to={paths.HOME}>
            <Image preview={false} src={logoMain} alt="Logo" width={170} />
          </Link>
          {/* <DesktopMenu>
            <ActionButtons>
              <Link to={paths.ABOUT}>
                <Button>About</Button>
              </Link>
              {user ? (
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  className="cursor-pointer"
                >
                  <Image
                    src={userLogo}
                    preview={false}
                    alt={user.name}
                    width={35}
                  />
                </Dropdown>
              ) : (
                <Link to={paths.LOGIN}>
                  <Button>Login</Button>
                </Link>
              )}
              {!user?.isAdmin ? (
                <Link to={paths.CART}>
                  <Button>
                    <ShoppingCartOutlined
                      style={{ color: "#0E4A7C", fontSize: 20 }}
                    />
                    {cart.items?.length ? <ShoppingDot /> : null}
                  </Button>
                </Link>
              ) : null}
            </ActionButtons>
          </DesktopMenu> */}
          <MobileMenu>
            {!user?.isAdmin ? (
              <Link to={paths.CART} style={{ position: "relative" }}>
                <ShoppingCartOutlined
                  style={{
                    color: "#0E4A7C",
                    fontSize: 22,
                  }}
                />
                {cart.items?.length ? (
                  <ShoppingDot>{cart.items.length || 0}</ShoppingDot>
                ) : null}
              </Link>
            ) : null}
          </MobileMenu>
        </HeaderWrapper>
      </Container>
    </>
  );
};

export { Header };
