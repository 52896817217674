import React from "react";
import { Col, Image, Row, Typography } from "antd";
import {
  StyledAbout,
  ContentBox,
  Content,
  BoxFooter,
  ContactInfo,
  BoxFooterTop,
} from "./About.styled";
import { ClockCircleFilled, MailFilled, PhoneFilled } from "@ant-design/icons";
import { Container } from "../../components/Container/Container";
import LogoImg from "./../../assets/imgs/logo.png";
import { Header } from "../../components/Header/Header";

const About: React.FC = () => {
  return (
    <>
      <Header heading="About Us" />
      <Container>
        <StyledAbout>
          <Image
            src={LogoImg}
            style={{ marginBottom: "2rem" }}
            alt="Company Logo"
            width={150}
            preview={false}
            height="auto"
          />
          <ContentBox>
            <Content>
              <Typography.Paragraph className="font-semibold">
                About Us
              </Typography.Paragraph>
              Formed in 2008 and based in the heart of Birmingham, Premier
              Distribution is a leading supplier of vape products, cleaning
              products, toilet rolls, bin bags and much more. We offer free
              delivery on our own vans, or you can come to our warehouse and
              collect goods yourself. We are open to the trade Monday to Friday
              9am till 5pm.
            </Content>
            <BoxFooterTop>
              <PhoneFilled />
              <span>07966 338 935</span>
            </BoxFooterTop>
            <BoxFooter>
              <Row>
                <Col span={12}>
                  <ContactInfo>
                    <span>
                      {" "}
                      <MailFilled /> Email:{" "}
                    </span>
                    navsalari@me.com
                  </ContactInfo>
                </Col>
                <Col span={12}>
                  <ContactInfo>
                    <span>
                      {" "}
                      <ClockCircleFilled /> Working Day/Hours :{" "}
                    </span>
                    Monday - Friday <br />
                    09:00AM - 05:00PM
                  </ContactInfo>
                </Col>
              </Row>
            </BoxFooter>
          </ContentBox>
        </StyledAbout>
      </Container>
    </>
  );
};

export { About };
