import React from "react";
import { useForm } from "react-hook-form";
import { Input } from "./../../components/Input/Input";
import {
  LoginForm,
  LoginButton,
  BottomText,
  BottomLink,
  LoginWrapper,
  ImageCenter,
} from "./Signup.styled";
import { useNavigate } from "react-router-dom";
import { paths } from "../../constants/paths";
import { useRegisterMutation } from "../../services/Auth/auth";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../../store/slice";
import { toast } from "react-hot-toast";
import LogoImg from "./../../assets/imgs/logoMain.png";
import { Divider, Image } from "antd";

const Signup: React.FC = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [register, { isLoading, error }] = useRegisterMutation();

  const onSubmit = async (data: any) => {
    try {
      const newData = { ...data };
      if (newData.password !== newData.confirmPassword) {
        toast.error("Password not matched");
        return;
      }
      delete newData.confirmPassword;

      toast.promise(register(newData).unwrap(), {
        error: (e) =>
          e
            ? Array.isArray(e)
              ? e[0].message
              : e.message
            : "Something went wrong",
        loading: "loading",
        success: (response: any) => {
          dispatch(setUser(response.user));
          dispatch(setToken(response.token));
          navigate(paths.HOME);
          return "user signup Successful";
        },
      });
    } catch (err) {
      console.error("Signup failed:", err);
    }
  };

  const inputProps = [
    {
      name: "name",
      type: "text",
      control: control,
      rules: { required: "Name is required" },
      placeholder: "Name",
    },
    {
      name: "email",
      type: "email",
      control: control,
      rules: { required: "Email is required" },
      placeholder: "Email",
    },
    {
      name: "address",
      type: "text",
      control: control,
      rules: { required: "Address is required" },
      placeholder: "Address",
    },
    {
      name: "contact",
      type: "text",
      control: control,
      rules: { required: "Contact is required" },
      placeholder: "Contact",
    },
    {
      name: "password",
      type: "password",
      control: control,
      rules: { required: "Password is required" },
      placeholder: "Password",
    },
    {
      name: "confirmPassword",
      type: "password",
      control: control,
      rules: { required: "Password is required" },
      placeholder: "Confirm Password",
    },
  ];

  return (
    <LoginWrapper>
      <LoginForm onSubmit={handleSubmit(onSubmit)}>
        <ImageCenter>
          <Image
            src={LogoImg}
            alt="Company Logo"
            width={180}
            preview={false}
            height="auto"
            style={{ marginBottom: "2rem" }}
          />
        </ImageCenter>
        {inputProps?.map((props) => (
          <Input key={props.name} {...props} />
        ))}
        <LoginButton type="submit" disabled={isLoading}>
          Signup
        </LoginButton>
        <Divider plain>OR</Divider>
        <BottomText>
          Already have an account?
          <BottomLink onClick={() => navigate(paths.LOGIN)}> login</BottomLink>.
        </BottomText>
      </LoginForm>
    </LoginWrapper>
  );
};

export { Signup };
