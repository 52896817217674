import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Input } from "./../../components/Input/Input";
import {
  LoginForm,
  LoginButton,
  BottomText,
  BottomLink,
  LoginWrapper,
  ImageCenter,
} from "./Login.styled";
import { useNavigate, useLocation } from "react-router-dom";
import { paths } from "../../constants/paths";
import { useAppDispatch } from "../../store/hooks";
import { useLoginMutation } from "../../services/Auth/auth";
import { User, setToken, setUser } from "../../store/slice";
import { toast } from "react-hot-toast";
import LogoImg from "./../../assets/imgs/logo.png";
import { Divider, Image } from "antd";

const Login: React.FC = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [login, { isLoading, error }] = useLoginMutation();

  const location = useLocation();

  const handleBackButton = () => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.go(1);
      navigate(paths.HOME);
    };
  };

  useEffect(() => {
    handleBackButton();
    return () => {
      window.onpopstate = null;
    };
  }, [location, navigate]);

  const onSubmit = async (data: any) => {
    try {
      toast.promise(login(data).unwrap(), {
        error: (e) =>
          e
            ? Array.isArray(e)
              ? e[0].message
              : e.message
            : "Something went wrong",
        loading: "loading",
        success: (response) => {
          if (response.token) dispatch(setToken(response.token));
          dispatch(setUser(response.user));
          navigate(paths.HOME);
          return "Login Successful";
        },
      });
    } catch (err) {
      console.error("Login failed:", err);
    }
  };

  const inputProps = [
    {
      name: "email",
      type: "email",
      control: control,
      rules: { required: "Email is required" },
      placeholder: "Email",
    },
    {
      name: "password",
      type: "password",
      control: control,
      rules: { required: "Password is required" },
      placeholder: "Password",
    },
  ];

  return (
    <LoginWrapper>
      <LoginForm onSubmit={handleSubmit(onSubmit)}>
        <ImageCenter>
          <Image
            src={LogoImg}
            alt="Company Logo"
            width={180}
            preview={false}
            height="auto"
            style={{ marginBottom: "2rem", alignSelf: "center" }}
          />
        </ImageCenter>
        {inputProps?.map((props) => (
          <Input key={props.name} {...props} />
        ))}
        <LoginButton type="submit" disabled={isLoading}>
          Login
        </LoginButton>
        <Divider plain>OR</Divider>
        <BottomText>
          Don't have account?
          <BottomLink onClick={() => navigate(paths.SIGNUP)}>
            {" "}
            create a new account
          </BottomLink>
          .
        </BottomText>
        <BottomText>
          If you sign up, you agree to the <br />
          <BottomLink onClick={() => console.log("Show Terms & Conditions")}>
            Terms & Conditions
          </BottomLink>{" "}
          and{" "}
          <BottomLink onClick={() => console.log("Show Privacy Policy")}>
            Privacy Policy
          </BottomLink>
          .
        </BottomText>
      </LoginForm>
    </LoginWrapper>
  );
};

export { Login };
