import React, { useEffect, useState } from "react";
import { MT, StyledFooter } from "./Footer.styled";
import { FooterProps } from "./Footer.interface";
import { ShoppingFilled } from "@ant-design/icons";
import { Row, Col } from "antd";
import { Link, useLocation } from "react-router-dom";
import { paths } from "../../constants/paths";
import { useAppSelector } from "../../store/hooks";

import { SvgGroup, SvgHome, SvgInfo, SvgUser } from "../AppSvgs";
import { Container } from "../Container/Container";

const Footer: React.FC<FooterProps> = () => {
  const user = useAppSelector((state) => state.auth.user);
  const cart = useAppSelector((state) => state.global.cart);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("home");

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === paths.HOME) {
      setActiveTab("home");
    } else if (currentPath.includes(paths.ORDER_VIEW)) {
      setActiveTab("cart");
    } else if (
      currentPath.includes(paths.PROFILE) ||
      currentPath.includes(paths.LOGIN) ||
      currentPath.includes(paths.SIGNUP)
    ) {
      setActiveTab("profile");
    } else if (currentPath.includes(paths.ABOUT)) {
      setActiveTab("about");
    } else {
      setActiveTab("");
    }
  }, [location]);

  if (activeTab === "profile" && !user) {
    return <></>;
  }

  return (
    <>
      <MT></MT>
      <StyledFooter>
        <Row justify="center" align="middle" className="footer">
          <Col span={6}>
            <Link
              to={paths.HOME}
              className={activeTab === "home" ? "active" : ""}
            >
              <SvgHome active={activeTab === "home"} />
              <div className="head">Home </div>
            </Link>
          </Col>
          <Col span={6}>
            <Link
              to={paths.ORDER_VIEW}
              className={activeTab === "cart" ? "active" : ""}
            >
              <SvgGroup active={activeTab === "cart"} />
              <div className="head">Orders</div>
            </Link>
          </Col>
          <Col span={6}>
            <Link
              to={user ? paths.PROFILE : paths.LOGIN}
              className={activeTab === "profile" ? "active" : ""}
            >
              <SvgUser active={activeTab === "profile"} />
              <div className="head">Profile</div>
            </Link>
          </Col>
          <Col span={6}>
            <Link
              to={paths.ABOUT}
              className={activeTab === "about" ? "active" : ""}
            >
              <SvgInfo active={activeTab === "about"} />
              <div className="head">About Us</div>
            </Link>
          </Col>
        </Row>
      </StyledFooter>
    </>
  );
};

export { Footer };
