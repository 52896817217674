import React from "react";
import {
  Col,
  Divider,
  Image,
  Row,
  Skeleton,
  Spin,
  Table,
  Typography,
} from "antd";
import { ProductInfo, ResponsiveRow, RowDivider } from "./ViewOrder.styled";
import { ViewOrderProps } from "./ViewOrder.interface";
import {
  useViewOrderQuery,
  useViewOrdersQuery,
} from "../../services/Auth/auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  GetOrder,
  OrderDeatil,
  OrderDetailItem,
  setSelectedOrder,
} from "../../store/slice";
import { Container } from "../../components/Container/Container";
import { Header } from "../../components/Header/Header";
import { useParams } from "react-router-dom";

export const ViewOrder: React.FC<ViewOrderProps> = ({}) => {
  const { id } = useParams<{ id?: string }>();
  const { data: orderData, isLoading } = useViewOrderQuery(id || "");
  const dispatch = useAppDispatch();
  const order = useAppSelector((state) => state.auth.order);

  React.useEffect(() => {
    // dispatch(setOrder(orderData));
    if (orderData) {
      const order = orderData.order as OrderDeatil;
      dispatch(setSelectedOrder(order));
    }
  }, [orderData]);

  return (
    <>
      <Header heading="Past Order" />
      <Container style={{ marginTop: 20 }}>
        {order && order?.items ? (
          order?.items.map((item: OrderDetailItem) => (
            <div key={item.product._id}>
              <Row>
                <Col span={6}>
                  <Image
                    width={85}
                    src={item.product.image}
                    alt={item.product.name}
                  />
                </Col>
                <Col span={18}>
                  <ResponsiveRow>
                    <ProductInfo>
                      <p className="name">{item.product.name}</p>
                      <p className="desc">{item.product.description}</p>
                    </ProductInfo>
                    <RowDivider>
                      <div className="price">£{item.product.price}</div>
                      <div>
                        <Typography.Paragraph>
                          Quantity: {item.quantity}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          Total Price: {+item.quantity * +item.product.price}
                        </Typography.Paragraph>
                      </div>
                    </RowDivider>
                  </ResponsiveRow>
                </Col>
              </Row>
              <Divider />
            </div>
          ))
        ) : (
          <Skeleton />
        )}
      </Container>
    </>
  );
};
