import { GetCategory } from "../../../services/Category";
import { GetProduct, Product } from "../../../services/Product";

export enum ViewType {
  "sm",
  "lg",
}

export interface GlobalStore {
  viewType: ViewType;
  categories: GetCategory;
  products: GetProduct;
  cart: Cart;
}

export interface Cart {
  items: CartItem[];
  type: "delivery" | "collection";
  subTotal: number;
  additionalNote: string;
}

export interface CartItem {
  quantity: number;
  product: Product;
  _id?: Product;
}
