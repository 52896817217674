import React from "react";
import {
  CartWrapper,
  CartItemList,
  CartItemWrapper,
  CartItemInfo,
  QuantityControls,
  OrderSummary,
  PlaceOrderButton,
  AdditionalNoteInput,
  OrderSummaryItem,
  CartWrapperEmpty,
} from "./Cart.styled";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Image, Select, Typography } from "antd";
import { Container } from "../../components/Container/Container";
import {
  Cart as CartType,
  CartItem,
  initCart,
  setCart,
  setUser,
} from "../../store/slice";
import { toast } from "react-hot-toast";
import {
  useAddOrderMutation,
  useGetUserQuery,
  useViewOrdersQuery,
} from "../../services/Auth/auth";
import { useNavigate } from "react-router-dom";
import { paths } from "../../constants/paths";
import emptyCartImg from "./../../assets/imgs/empty-cart 1.png";
import { Header } from "../../components/Header/Header";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { Product } from "../../services/Product";

const Cart: React.FC = () => {
  const cart = useAppSelector((state) => state.global.cart);
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token);
  const [addOrder] = useAddOrderMutation();
  const navigate = useNavigate();
  const { refetch } = useViewOrdersQuery();
  const [loading, setLoading] = React.useState(false);

  const calculateSubTotal = React.useMemo((): number => {
    let subTotal = 0;

    cart?.items.forEach((item: CartItem) => {
      subTotal += +item.product.price * item.quantity;
    });

    return parseFloat(subTotal.toFixed(2));
  }, [cart.items]);

  const handlePlaceOrder = async () => {
    if (token) {
      const newStateCart: any = { ...cart, subTotal: calculateSubTotal };
      newStateCart.items = newStateCart.items.map((item: CartItem) => {
        const { product, ...newItem } = item;
        newItem._id = product;
        return newItem;
      });
      // console.log({ newStateCart });
      setLoading(true);
      toast.promise(addOrder(newStateCart).unwrap(), {
        error: (e) =>
          e
            ? Array.isArray(e)
              ? e[0].message
              : e.message
            : "Something went wrong",
        loading: "loading",
        success: () => {
          navigate(paths.ORDER_SUCCESS);
          dispatch(setCart(initCart as CartType));
          setLoading(false);
          refetch();
          return "Add order Successful";
        },
      });
    } else {
      toast.error("please login first to palce order");
      navigate(paths.LOGIN);
    }
  };

  if (cart.items.length === 0) {
    return (
      <>
        <Header heading="My Basket" />
        <Container>
          <CartWrapperEmpty>
            <div>
              <Image
                src={emptyCartImg}
                alt="Company Logo"
                width={300}
                preview={false}
                height="auto"
                style={{ marginTop: "2rem" }}
              />
              <Typography.Title level={4}>Cart is Empty</Typography.Title>
            </div>
          </CartWrapperEmpty>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header heading="My Basket" />
      <Container>
        <CartWrapper>
          <CartItemList>
            <ProductsList
              products={
                cart.items.map((item: CartItem) => ({
                  ...item.product,
                  quantity: item.quantity,
                })) as Product[]
              }
              showCross={true}
            />
          </CartItemList>
          <OrderSummary>
            <OrderSummaryItem>
              <span>Items:</span> <span>{cart.items.length}</span>
            </OrderSummaryItem>
            <OrderSummaryItem>
              <span>Delivery Type:</span>{" "}
              <span>
                <Select
                  defaultValue={cart.type}
                  onChange={(val) => dispatch(setCart({ ...cart, type: val }))}
                >
                  <Select.Option value="delivery">Delivery</Select.Option>
                  <Select.Option value="collection">Collection</Select.Option>
                </Select>
              </span>
            </OrderSummaryItem>
            <OrderSummaryItem>
              <p style={{ marginBottom: 0 }}>Additional Note:</p>
              <AdditionalNoteInput
                id="additionalNote"
                value={cart.additionalNote}
                onChange={(e) =>
                  dispatch(setCart({ ...cart, additionalNote: e.target.value }))
                }
              />
            </OrderSummaryItem>
            <OrderSummaryItem>
              <span>Sub Total:</span> <strong>{calculateSubTotal}</strong>
            </OrderSummaryItem>
            <PlaceOrderButton onClick={handlePlaceOrder} disabled={loading}>
              Place Order
            </PlaceOrderButton>
          </OrderSummary>
        </CartWrapper>
      </Container>
    </>
  );
};

export { Cart };
