import { Router } from "./router/router";

import { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import GlobalStyle from "./theme/globalStyle";
import useBreakpoint from "./hooks/useBreakpoints";
import { useDispatch } from "react-redux";
import { Toaster, ToastOptions } from "react-hot-toast";
import { ViewType, setViewType } from "./store/slice";

function App() {
  const screen = useBreakpoint();
  const dispath = useDispatch();

  const viewType = window.innerWidth <= 768 ? ViewType.sm : ViewType.lg;
  // dispath(setViewType(viewType));

  const toastOption: ToastOptions = {
    position: "top-center",
    duration: 2000,
  };

  return (
    <ThemeProvider theme={{ ...theme, screen }}>
      <GlobalStyle />
      <Router />
      <Toaster {...toastOption} />
    </ThemeProvider>
  );
}
export default App;
